const data = [
	{
		name:"Home",
	},
								{
									name:"Attention Training",
				  			},
								{
									name:"Griffinot",
								},
								{
									name:"Snowball",
								},
								{
									name:"Mental Snapp",
								},
								{
									name:"Shrinc",
								},
								{
									name:"Far Nearer",
							 },
							 {
									name:"Sit Spot",
							}
				]

export default data;
