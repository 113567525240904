import React, { Component } from "react";
import "./App.css";
import {
  Container,
  Container2,
  WebImg,
  Summary,
  GitHubLink,
  Left,
  SourceLink,
  GithubLogo,
  Project,
  Iphone,
  Headline,
  Item,
  Ul,
  GithubText,
  Header,
  GsgLogo,
  GsgLink,
  HeaderText,
} from "./app.style";
import BuiltByZaat from "./BuiltByZaat";
import data from "./data";
import data2 from "./data2";

import griff from "./assets/2.mp4";
import shrinc from "./assets/7.mp4";
import sitspot from "./assets/2.mp4";
import snowball from "./assets/6.mp4";
import mentalsnapp from "./assets/1.mp4";
import farnearer from "./assets/4.mp4";
import adhd from "./assets/3.mp4";
import iphone from "./assets/iphone.png";
import githubLogo from "./assets/github.png";
import gsgLogo from "./assets/gsg.png";
import zaatLogo from "./assets/logo_zaat-02.svg";
import arrowDown from "./assets/arrow_down.svg";
import AOS from "aos";

AOS.init({
  useClassNames: false,
  offset: 200,
  delay: 50,
  duration: 1000,
  easing: "ease-in-out",
  once: false,
  mirror: true,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      elemntNum: -1,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    var headerLenght = document.getElementById("Home").clientHeight;
    var elementHeight = Math.round(
      (document.body.clientHeight - headerLenght) / 7
    );
    var lastScroll = window.scrollY;

    var elementPosition = [];

    elementPosition.push(headerLenght);

    for (var i = 1; i <= 8; i++) {
      elementPosition.push(headerLenght + elementHeight * i);
    }
    if (lastScroll < headerLenght && lastScroll > 0) {
      this.setState({
        elemntNum: 0,
      });
    }

    if (lastScroll > headerLenght) {
      for (i = 1; i <= 8; i++) {
        if (lastScroll <= elementPosition[i]) {
          this.setState({
            elemntNum: i,
          });
          break;
        }
      }
    }
  }

  handleClick(e) {
    if (window.innerWidth >= 750) {
      setTimeout(() => {
        window.scrollTo(0, window.scrollY + 200);
      }, 1);
    } else {
      setTimeout(() => {
        window.scrollTo(0, window.scrollY + 100);
      }, 1);
    }
    e.persist();
    console.log(getIndex(e.target.name));
    this.setState({
      elemntNum: getIndex(e.target.name),
    });
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <Container2>
            <Container >
              <Header id="Home">
                <Headline className="top"> Projects built by GSG Code Academy</Headline>
                <Headline>
                  <GsgLink href={"https://zaat.dev/"}>
                    <GsgLogo src={zaatLogo} />
                    <HeaderText>Hire us to build a project</HeaderText>
                  </GsgLink>

                  <GsgLink href={"https://gazaskygeeks.com/talent/"}>
                    <GsgLogo src={gsgLogo} />
                    <HeaderText>Hire a developer to join your team</HeaderText>
                  </GsgLink>
                </Headline>
                <Headline className="arrow">
                  <GsgLogo src={arrowDown} />
                </Headline>
              </Header>
              {data.map(({ image, link, github, summary, name }, i) => {
                return (
                  <React.Fragment>
                    <Project
                    id={name}
                    data-aos="fade-left"
                    >
                      <SourceLink>
                        <Iphone src={iphone} />
                        <a href={github}>
                          <WebImg autoPlay muted loop>
                            <source src={getImage(image)} type="video/mp4" />
                          </WebImg>
                        </a>
                      </SourceLink>
                      <Left>
                        <Headline>{name}</Headline>
                        <Summary>{summary}</Summary>
                        <GitHubLink href={github}>
                          <GithubLogo src={githubLogo} />
                          <GithubText> View Source Code on Github</GithubText>
                        </GitHubLink>
                      </Left>
                    </Project>
                  </React.Fragment>
                );
              })}
            </Container>
            <Ul onScroll={this.handleScroll}>
              {data2.map(({ name }, i) => {
                let href = "#" + `${name}`;
                let index = i;
                var linkStyle;
                if (index === this.state.elemntNum) {
                  if (window.innerWidth >= 750)
                  {
                  linkStyle = {
                    color: "white",
                    backgroundColor: "#292929",
                  };
                }
              else {
                linkStyle = {
                  color: "white",
                  backgroundImage: "linear-gradient(to left, black, #292929 ,black )",
                };
              }
}
                return (
                  <React.Fragment>
                    <Item
                      style={linkStyle}
                      onClick={this.handleClick}
                      name={name}
                      href={href}
                    >
                      {window.innerWidth >= 750 ? (
                        `${name}`
                      ) : (
                        <div> </div>
                      )}
                    </Item>
                  </React.Fragment>
                );
              })}
            </Ul>
          </Container2>
        </header>
        <BuiltByZaat />
      </div>
    );
  }
}
export default App;

function getImage(name) {
  switch (name) {
    case "griff":
      return griff;
    case "shrinc":
      return shrinc;
    case "sitspot":
      return sitspot;
    case "snowball":
      return snowball;
    case "mentalsnapp":
      return mentalsnapp;
    case "farnearer":
      return farnearer;
    default:
      return adhd;
  }
}

function getIndex(name) {
  switch (name) {
    case "Attention Training":
      return 1;
    case "Griffinot":
      return 2;
    case "Snowball":
      return 3;
    case "Mental Snapp":
      return 4;
    case "Shrinc":
      return 5;
    case "Far Nearer":
      return 6;
    case "Sit Spot":
      return 7;
    default:
      return 0;
  }
}
