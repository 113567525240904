import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-content: space-between;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  @media (max-width: 750px) {
  margin-left: 11%;
  }
  @media (max-width: 500px) {
  margin-left: 0;
  }
`;

export const Container2 = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  padding-left:10%;
  @media (max-width: 750px) {
    padding-left:0;
}
`;
export const Right = styled.div`
  display: flex;
  flex-wrap: no-wrap;

`;


export const Left = styled.div`
flex-direction: column;
display: flex;
width:40%;
@media (max-width: 900px) {
  margin-left:-11%;
}
@media (max-width: 750px) {
  margin-left:-7%;
  align-items: center;
  width: 80vw;
  margin-top:80%;
}
@media (max-width: 500px) {
  margin-top: 36%;
}`;

export const GsgLogo = styled.img`
  width: auto;
  height: 230px;
  @media (max-width: 900px) {
    height: 170px;
}
  @media (max-width: 750px) {
    height: 15px;
    margin-right:5px;
    }
`;
export const WebImg = styled.video`
  position: relative;
  height: 442.5px;
  z-index: 0;
  padding-left:19px;
  top: 43px;
  left: -77px;
  @media (max-width: 900px) {
    left: -16px;
  }
  @media (max-width: 750px) {
  height: 377.5px;
  z-index: 0;
  top: 30px;
  left: -32px;
      }


`;

export const Iphone = styled.img`
  height: 590px;
  padding-left:20px;
  position: absolute;
  top: 0;
  left: 0;
  @media (max-width: 900px) {
    padding-left: 81px;
  }
  @media (max-width: 750px) {
    padding-left: 69px;
        height: 500px;
      }

`;

export const SourceLink = styled.div`
  display:flex;
  position: relative;
  padding:30px  100px;
  top: 0;
  left: 0;
`;

export const SourceLink2 = styled.div`
display: none;
@media (max-width: 750px) {
display:flex;
position: relative;
padding:30px  100px;
top: 0;
left: 0;
}
`;
export const GitHubLink = styled.a`
  margin: 0 0 10px 0;
  align-items: center;
  color:white;
  display: flex;
  flex-wrap: no-wrap;
`;

export const GsgLink = styled.a`
  color:white;
  text-decoration: none;
  display: flex;
  flex-direction:column;
  align-items:center;
  @media (max-width: 750px) {
    flex-direction:row;
    justify-content:center;

      }
`;
export const Summary = styled.p`
  font-size:28px;
  color:white;
  text-align: left;

  @media (max-width: 900px) {
    font-size:20px;
  }
  @media (max-width: 750px) {
    text-align: center;
    font-size:15px;
      }
`;
export const GithubText = styled.p`
  font-size:20px;
  color:white;
  @media (max-width: 900px) {
    font-size: 18px;
  }

@media (max-width: 750px) {
  font-size: 15px;
  text-align:center;
}
`;


export const HeaderText = styled.p`
  font-size:30px;
  color:white;
  @media (max-width: 900px) {
    font-size: 24px;

  }

@media (max-width: 750px) {
  text-align:center;
  font-size:18px;
}

`;
export const GithubLogo = styled.img`
margin-right:10px;
width:25px;
`;

export const Header = styled.div`
  display: flex;
  align-items: space-between;
  flex-direction:column;
  width:83vw;
  height:100vh;
  justify-content: space-around;
  @media (max-width: 900px) {
    margin-left: 17%;
    padding-top: 32px;
    align-items: center;
    display: flex;
    flex-direction:column;
    height:auto;

  }
  @media (max-width: 750px) {
    margin-left: -7%;
    padding-top: 32px;
    align-items: center;
    display: flex;
    flex-direction:column;
    height:auto;
}
`;


export const Headline = styled.h1`
  display:flex;
    text-align: left;
    justify-content: space-around;
    color:white;
    font-size:60px;
    @media (max-width: 900px) {
      font-size: 40px;
    }
  @media (max-width: 750px) {
    font-size: 30px;
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    text-align:center;
  }

`;
export const Item = styled.a`
display: flex;
margin-left:3px;
color:white;
text-decoration: none;
text-align:left;
padding:10px;
&:hover {
  background-color: rgba(168, 168, 168, 0.3);
}
`
export const Ul = styled.div`
  height:100vh;
  flex-direction: column;
  top:0;
  left:0;
  position: fixed;
  display:flex;
  justify-content:space-around;
  align-content:center;
  background:#000000c7;
  @media (max-width: 750px) {
    background-color:black;
    width:100%;
    justify-content:space-between;
    flex-direction: row;
    bottom:0;
    top:auto;
    height: 2rem;
}
`

export const Project = styled.div`
  display: flex;
  justify-content: center;
  padding-top:20%;
  height:100vh;
  @media (max-width: 750px) {
    padding-bottom:26%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
@media (max-width: 500px) {
  padding-bottom:1%;
}

`;
