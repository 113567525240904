const data = [
				{
					name:"Attention Training",
				  image:"adhd",
				  github:"https://github.com/fack2/attention-training",
					link:"http://adhd.gsgapp.io/",
					summary:"Attention Training is a project that helps you to know if you have an ADHD mental disorder and which type you may have. It gives you more information about the ADHD type you have and tips to help you improve your skills and overcome your challenges.",
				},
				{
									name:"Griffinot",
								  image:"griff",
								  github:"https://github.com/fack2/ptfb-griffinot",
									link:"http://griff.gsgapp.io/",
									summary:"Making this website to best support parents in supporting their children, they can use the videos provided in the app to improve their child’s motor skills, and they know what activites to do to help their child improve, and how to do them correctly.",
								},
								{
									name:"Snowball",
								  image:"snowball",
								  github:"https://github.com/fack2/snowball",
									link:"http://snowball.gsgapp.io/",
									summary:"The app tells the user all the ways they can take climate action, on a simple, easy to navigate interface. The user can swipe to make a commitment to take that action (ie. ‘buy locally produced, seasonal food wherever possible’). .Most importantly the app will show the user how many people, around the world, have already committed to each action",
								},
								{
									name:"Mental Snapp",
								  image:"mentalsnapp",
								  github:"https://github.com/GSG-G7/mental-snapp-v2",
									link:"http://mental-snapp.gsgapp.io/",
									summary:"Mental Snapp is a mobile-first web application which allows people in general and women in particular to write down about their feelings or life through answering a set of questions.",
								},
								{
									name:"Shrinc",
								  image:"shrinc",
								  github:"https://github.com/GSG-G7/shrinc",
									link:"http://shrinc.gsgapp.io/",
									summary:"Make app to facilitate user reaching to the appropriate therapy and connect with therapist in the right time with less costs.",
								},
								{
									name:"Far Nearer",
								  image:"farnearer",
								  github:"https://github.com/GSG-G7/far-nearer-v2",
									link:"http://far-nearer.gsgapp.io/",
									summary:"A website that lets the users find and add reviews on places they have visited, and rating them based on a few questionnaires that we designed to help us determine the sustainability factors and key features of places!",
							 },
							 {
									name:"Sit Spot",
								  image:"sitspot",
								  github:"https://github.com/GSG-G7/sitspot-v2",
									link:"http://sitspot.gsgapp.io/",
									summary:"Travellers open the website to search for places that they can travel to while seeing	their sustainability factor, which will help them feel good about their travel choices",
							}
				]

export default data;
